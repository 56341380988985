import service from './http';

// 查询用户信息
export const getUserInfo = () =>
  service.request({
    method: 'get',
    url: '/api/user',
  });

// 退出登录
export const signOut = () =>
  service.request({
    method: 'post',
    url: '/api/user/sign.out',
  });
// Code 转 Token
export const parseCode = (code: string) =>
  service.request<API.Response<string>>({
    method: 'get',
    url: '/api/user/token.code.parse',
    params: {
      appId: 'webtranslation',
      code,
    },
  });
