import { useState } from 'react';
import { Spin,Table, Pagination } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import Empty from "@/components/record/empty"
import dayjs from 'dayjs';
import { getUsageList } from '@/api/subtitles';
import { localizeTime } from '@/utils/i18n';
import './index.less';
import "@/components/style/index.less"
import styles from '@/components/style/index.module.less';

function Usage() {
  const [curr, setCurr] = useState(1);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState(10);
  const { t } = useTranslation();

  const columns: ColumnsType<API.DeductionItem> = [
    {
      title: t('startTime'),
      dataIndex: 'start_time',
      render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: t('endTime'),
      dataIndex: 'end_time',
      render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: t('useTime'),
      dataIndex: 'deduction_amount',
      render: (text) => localizeTime(text),
    },
  ];

  const getUsageRequest = useQuery({
    queryKey: ['getUsage', curr, size],
    queryFn: () => getUsageList(curr, size).then((res) => {
      if (res.data.data.total) {
        setTotal(res.data.data.total);
      }

      return res;
    }),
  });

  const onChange = (page: number, pageSize: number) => {
    setCurr(page);
    setSize(pageSize);
  };

  return (
    <div className="source-table-content">
      {getUsageRequest.isLoading && <Spin className="!block mx-auto" size="large" />}
      {getUsageRequest.data?.data.data.records.length === 0 && <Empty text={t('noUse')} />}
      {getUsageRequest.data?.data.data.records?.length > 0 &&
      <>
        <Table
          rowKey="deduction_id"
          pagination={false}
          columns={columns}
          dataSource={getUsageRequest.data?.data.data.records}
          loading={getUsageRequest.isLoading}
          className="custom-table"
          locale={{ emptyText: t('NoData') }}
          bordered={false}
        />
         <Pagination className={styles.paginationRecord} pageSize={size}showSizeChanger={false} current={curr} total={total} hideOnSinglePage onChange={onChange} />
      </>}

    </div>
  );
}

export default Usage;
