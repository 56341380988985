import logoIcon from '@/assets/images/translatorLogo.svg';
import { useTranslation } from 'react-i18next';
import MenuContent from "./menu";
import './index.less';

function VipMenu() {
  const { t } = useTranslation();
  const toHome=()=>{
    window.open(import.meta.env.VITE_FELO_HOME_URL);
  }
  return (
    <div className='vip-menu'>
      <div className='menu-top'>
        <img className='menu-icon' src={logoIcon} onClick={toHome}/>
        <div className='menu-title' onClick={toHome}>{t('menuSubtitles')}</div>
      </div>
      <MenuContent />
    </div>

  );
}

export default VipMenu;
