/* eslint-disable no-param-reassign */
import { FocusEvent, KeyboardEvent, useRef } from 'react';
import styles from '@/components/style/index.module.less';

interface TitletemProps {
  text: string;
  onSave?: (text: string) => void;
}

function TitleItem({text,onSave}: TitletemProps) {
  const inputRef = useRef<HTMLParagraphElement>(null);
  const prevText = useRef(text);

  const onBlur = (ev: FocusEvent<HTMLDivElement>) => {
    const trimedText = ev.target.innerText.trim();
    if (prevText.current === trimedText) {
      if (prevText.current !== ev.target.innerText) {
        ev.target.innerText = prevText.current;
      }
      return;
    }
    if (ev.target.innerText === '') {
      ev.target.innerText = prevText.current;
      return;
    }
    prevText.current = trimedText;
    ev.target.innerText = trimedText;
    onSave?.(trimedText);
  };

  const onKeyDown = (ev: KeyboardEvent<HTMLDivElement>) => {
    const el = ev.target as HTMLDivElement;

    // 看下是否保存
    if (ev.code === 'Enter') {
      ev.preventDefault();
      el.blur();
    }
    // 取消修改
    if (ev.code === 'Escape') {
      el.innerText = prevText.current;
      el.blur();
    }
  };


  return (
    <div
      ref={inputRef}
      className={styles.TitleItem}
      contentEditable={true}
      spellCheck={false}
      suppressContentEditableWarning
      onBlur={onBlur}
      onKeyDown={onKeyDown}>
     {text}
    </div>
  );
}

export default TitleItem;
