import { useTranslation } from 'react-i18next';
import { Input,message} from 'antd';
import { useState,useEffect } from 'react';
import { useAuthContext } from '@/hooks/auth';
import { createfeedback } from '@/api/subtitles';
import SvgIcon from '@/components/SvgIcon';
import logoICon from "@/assets/icons/logo.svg";
import resultIcon from "@/assets/icons/result.svg";
import styles from "./index.module.less";
import Upload from "./uploadImage";

const { TextArea } = Input;
function Feedback() {
  const { t } = useTranslation();
  const { state } = useAuthContext();
  const { user } = state;
  const [input,setInput] = useState<string>("");
  const [emailInput,setEmailInput] = useState<string>(user?.email);
  const [version,setVersion] = useState<string>("");
  const [subType,setSubType] = useState<string>("");
  const [imageList,setImagesList]= useState<any[]>([]);
  const [resultSuccess,setResultSuccess] = useState(false);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const from = searchParams.get('from');
    const v = searchParams.get('v');
    if(v){
      setVersion(v);
    }
    let subType;
    switch(from) {
      case 'desktop':
        subType = 'WINDOWS_APP';
        break;
      case 'plugin':
        subType = 'CHROME_APP';
        break;
      default:
        subType = 'WEB';
    }
    setSubType(subType);
  }, []);
  const toHome=()=>{
    window.open(import.meta.env.VITE_FELO_HOME_URL);
  }
  const inputChange=(e:any)=>{
    setInput(e.target.value);
  }
  const submitFeedback=async()=>{
    try{
      if(!input){
        return;
      }
      if(input && input.length<=4){
        return;
      }
      const info = {
        app_id:'subtitle',
        app_sub_type:subType,
        contact:emailInput,
        content:input,
        images:imageList,
        app_version:version
      }
      const data = await createfeedback(info)
      if(data.status===200){
        setResultSuccess(true)
      }else{
        message.error(t('senderror'))
      }
    }catch(error){
      message.error(t('senderror'))
    }

  }
  const inputEmailChange=(e:any)=>{
    setEmailInput(e.target.value);
  }
  return (
    <div className={styles.feedbackContent}>
        <div className={styles.feedbackHeader}>
          <img className={styles.logoicon} onClick={toHome} src={logoICon}/>
          <div className={styles.menuTitle} onClick={toHome}>{t('menuSubtitles')}</div>
        </div>
        {
          resultSuccess?
          <>
          <img className={styles.resultIcon} src={resultIcon} />
          <div className={styles.resultText}>{t('receivedFeedback')}</div>
          </>:<div className={styles.feedbackCenterContent}>
        <div className={styles.feedbackCenterText}>{t('feedback')}</div>
        <div className={styles.feedbackMatter}>
          <div className={styles.matterTitle}><span>*</span>{t('describeProblem')}</div>
          <div className={styles.textAreaContent}>
            <TextArea maxLength={2000} placeholder={t('feedbackContent')} spellCheck={false} value={input} onChange={inputChange} className={styles.textareaMatter}/>
          </div>

          <div className={styles.maxCountText}>{input.length}/2000</div>
          <div className={styles.matterTitle}>{t('printscreen')}</div>
          <Upload setImagesList={setImagesList}/>
        </div>
        <div className={styles.feedbackMatter}>
          <div className={styles.matterTitle}>{t('emailOrPhone')}</div>
          <div className={styles.textAreaContent}>
            <TextArea  maxLength={50} placeholder={t('emailOrPhone')} style={{height:'16px'}} spellCheck={false} value={emailInput} onChange={inputEmailChange} className={styles.textareaMatter}/>
          </div>
          <div className={styles.maxCountText}>{emailInput.length}/50</div>
          <div className={styles.feedbackButtonContent}>
            <div onClick={submitFeedback} className={`${input.length >4?styles.buttonContent:styles.nobuttonContent}`} >{t('send')}</div>
          </div>
        </div>
        <div className={styles.feedbackFooter}>
          <div className={styles.footerTitle}>{t('contactUs')}</div>
          <div className={styles.mailbox}>{t('mailbox')}：<span><a href="mailto:support@sparticle.com">support@sparticle.com</a></span></div>
          <div className={styles.icons}>
          <a key='facebook' href="https://www.facebook.com/felo.translator/" target="_blank">
              <SvgIcon  className={styles.icon} name='facebook'/>
            </a>
            <a key='twitter' href="https://twitter.com/FeloApp" target="_blank">
              <SvgIcon className={styles.icon} name='twitter'/>
            </a>
            <a key='youtube' href="https://www.youtube.com/channel/UC3_iFnHLJAnJMo99NOAtnpQ" target="_blank">
                <SvgIcon className={styles.icon} name='youtube'/>
            </a>
          </div>
        </div>
      </div>
      }
    </div>
  );
}
export default Feedback;
