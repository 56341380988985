import {  Outlet } from 'react-router-dom';
import VipMenu from "../menu/index"
import './index.less';

function Home() {
  return (
    <div className='home-div'>
      <div className='home-menu'>
        <VipMenu />
      </div>
      <div className='right-content'>
        <Outlet />
      </div>
    </div>

  );
}

export default Home;
