import { Route, Routes,Navigate } from 'react-router-dom';
import Record from "@/pages/record/index"
import Keyword from "@/pages/keyword/index"
import Detail from "@/pages/record/detail"
import Feedback from "@/pages/feedback/index"
import Account from "@/pages/account/index"
import Home from '@/pages/home/index';

function RouterContainer() {
  return (
    <Routes>
      <Route path="/feedback" element={<Feedback />} />
      <Route
        path="/"
        element={
          <>
            <Navigate to="record" />
          </>
        }
      />
      <Route
        path="/"
        element={
          <Home />
        }
      >
        <Route path="record" element={<Record />} />
        <Route path="keyword" element={<Keyword />} />
        <Route path="record/:id" element={<Detail />} />
        <Route path="account" element={<Account />} />
      </Route>
    <Route path="*" element={<Navigate to="/record" />} />

  </Routes>
  );
}

export default RouterContainer;
