import { useEffect, useRef } from 'react';
import SubtitleMQTTClient from '@/utils/mqtt';
import { getWebsiteMqttInfo } from '@/api/subtitles';

interface IProps {
  roomId?: string;
  onMessage: (msg: API.SubtitleItem) => void;
}

function useSubtitleMqtt({ roomId, onMessage }: IProps) {
  const isDestroyed = useRef(false);
  const clientRef = useRef<SubtitleMQTTClient<API.SubtitleItem>>();
  const lastFocusTime = useRef<number>(Date.now());

  useEffect(() => {
    if (!roomId) {
      return;
    }

    const onFocus = () => {
      const now = Date.now();

      if (now - 5000 > lastFocusTime.current) {
        lastFocusTime.current = now;

        if (!clientRef.current) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          initClient();
        }
      }
    };

    window.addEventListener('focus', onFocus);

    const getMqttInfo = () => getWebsiteMqttInfo(roomId).then((res) => {
      if (res.data.status !== 200) {
        throw new Error('getMqttInfo Failed');
      }

      return res.data.data;
    });

    const initClient = () => {
      getMqttInfo().then((res) => {
        if (!res.status || isDestroyed.current) {
          return;
        }

        console.log('MQTT Connecting');

        clientRef.current = new SubtitleMQTTClient<API.SubtitleItem>({
          roomId,
          info: res,
          getToken: () => getMqttInfo(),
          onMessage,
          topics: [res.website_translate_topic],
          onLoaded: () => {},
        });
      });
    };

    initClient();

    // eslint-disable-next-line consistent-return
    return () => {
      isDestroyed.current = true;

      if (clientRef.current) {
        clientRef.current.destroy();
      }

      window.removeEventListener('focus', onFocus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);
}

export default useSubtitleMqtt;
