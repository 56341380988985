import { useState } from 'react';

// 一个数据只能存一次的 Hooks
function useSets(): [(string | number)[], (item: string | number) => void, (item: string | number) => void] {
  const [state, setState] = useState<(string | number)[]>([]);

  const add = (item: string | number) => {
    setState((prevState) => {
      if (prevState.includes(item)) {
        return prevState;
      }

      return [...prevState, item];
    });
  };

  const remove = (item: string | number) => {
    setState((prevState) => {
      const indexOf = prevState.indexOf(item);

      if (indexOf === -1) {
        return prevState;
      }

      const nextState = [...prevState];
      nextState.splice(indexOf, 1);

      return nextState;
    });
  };

  return [
    state,
    add,
    remove,
  ];
}

export default useSets;
