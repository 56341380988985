/* eslint-disable import/prefer-default-export */

/**
 * 格式化秒
 * @param {number} seconds
 * @returns {string}
 */
export const formatSeconds = (seconds: number) => {
  const totalSeconds = Math.round(seconds); // 四舍五入到最近的整数秒
  const hours = Math.floor(totalSeconds / 3600); // 整除3600得到小时数
  const minutes = Math.floor((totalSeconds % 3600) / 60); // 求余后再整除60得到分钟数
  const remainingSeconds = totalSeconds % 60; // 求余得到剩余的秒数

  // 格式化每个部分，使其始终为两位数
  const hoursStr = hours.toString().padStart(2, '0');
  const minutesStr = minutes.toString().padStart(2, '0');
  const secondsStr = remainingSeconds.toString().padStart(2, '0');

  return `${hoursStr}:${minutesStr}:${secondsStr}`;
};
