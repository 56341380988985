import { Avatar,Dropdown } from 'antd';
import { useAuthContext } from '@/hooks/auth';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { signOut } from '@/api/api';
import type { MenuProps } from 'antd';
import Bill from "./components/bill";
import SvgIcon from '@/components/SvgIcon';
import safetyIcon from "@/assets/icons/safety.svg"
import userInfoIcon from "@/assets/icons/userInfo.svg"
import arrowRighticon from "@/assets/icons/arrow-right.svg"
import styles from '@/components/style/index.module.less';

function Account() {
  const { t } = useTranslation();
  const { state } = useAuthContext();
  const { user } = state;
  const openSetClick=()=>{
    const path = `${import.meta.env.VITE_FELO_API_URL}/account`;
    window.open(path);
  }
  const openSafetyClick=()=>{
    const path = `${import.meta.env.VITE_FELO_API_URL}/account/security`;
    window.open(path);
  }
  const logoutClcik=()=>{
    signOut();
    Cookies.remove(import.meta.env.VITE_COOKIE_SUFFIX);
    Cookies.remove('subtitlestoken');
    window.location.href = import.meta.env.VITE_FELO_HOME_URL;
  }
  const items: MenuProps['items'] = [
    {
      key: 'account',
      label: (
        <div className={styles.userDataContentMenu}>
            <Avatar
              className={styles.felosubtitleavatar}
              style={{ backgroundColor: user?.picture?.indexOf('http') >= 0 ? 'transparent' : 'rgb(36, 141, 247)' }}
              size={44}
              src={user?.picture?.indexOf('http') >= 0 ? user.picture : null}
            >
              {user?.email?.charAt(0).toLocaleUpperCase()}
            </Avatar>
            <div className={styles.userDataContentInfo}>
              <div className={styles.useName} >{user?.name}</div>
              <div className={styles.userEmail} >{user?.email}</div>
            </div>
        </div>
      ),
    },
    {
      key: 'userInfo',
      label: (
        <div className={styles.menupropsItem} onClick={openSetClick}>
          <img src={userInfoIcon} className={styles.icon}/>
          <div className={styles.text}>{t('userInfo')}</div>
          <img src={arrowRighticon}  className={styles.rightIcon}/>
        </div>
      ),
    },
    {
      key: 'safety',
      label: (
        <div className={styles.menupropsItem} onClick={openSafetyClick}>
          <img src={safetyIcon} className={styles.icon}/>
          <div className={styles.text}>{t('safety')}</div>
          <img src={arrowRighticon}  className={styles.rightIcon}/>
        </div>
      ),
    },
    {
      key: 'logout',
      label: (
        <div className={styles.logoutItem}>{t('logOut')}</div>
      ),
      onClick: async () => {
        logoutClcik();
      },
    },
  ];
  return (
    <div className={styles.pageContent}>
      <div className={styles.pageTitle}>
        <div>{t('accountManagement')}</div>
        <div className={styles.pageTitleRight}>
          <Dropdown menu={{ items }} overlayClassName={styles.dropdownCustom} placement="bottomRight">
            <div className={styles.iconItem}>
              <SvgIcon name="set"  />
            </div>
            </Dropdown>
        </div>
      </div>
      <div className={styles.userData}>
        <div className={styles.userTitle}>{t('userData')}</div>
        <div className={styles.userDataContent}>
            <Avatar
              className={styles.felosubtitleavatar}
              style={{ backgroundColor: user?.picture?.indexOf('http') >= 0 ? 'transparent' : 'rgb(36, 141, 247)' }}
              size={44}
              src={user?.picture?.indexOf('http') >= 0 ? user.picture : null}
            >
              {user?.email?.charAt(0).toLocaleUpperCase()}
            </Avatar>
            <div className={styles.userDataContentInfo}>
              <div className={styles.useName} >{user?.name}</div>
              <div className={styles.userEmail} >{user?.email}</div>
            </div>
        </div>
        <div className={styles.pageborder}></div>
        <Bill />
      </div>
    </div>

  );
}

export default Account;
