import ReactDOM from 'react-dom/client';
import App from './App';
import './global.less';
// import 'antd/dist/antd.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
    <App />,
  // </React.StrictMode>,
);
