import styles from '@/components/style/index.module.less';
import emptyicon from "@/assets/icons/empty.svg"
interface EmptyProps {
  text:string
}
function Empty({text}:EmptyProps) {
  return (
    <div className={styles.translatorEmpty}>
      <img src={emptyicon} className={styles.emptyIcon}/>
      <div className={styles.emptyText}>{text}</div>
    </div>

  );
}

export default Empty;
