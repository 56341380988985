/* eslint-disable no-param-reassign */
import { FocusEvent, KeyboardEvent, MouseEvent, useRef } from 'react';

interface SubtitleItemProps {
  className: string;
  textClassName: string;
  subTextClassName: string;
  loading?: boolean;
  text: string;
  subText?: string;
  onSave?: (text: string) => Promise<unknown> | void;
}

function SubtitleItem({
  className,
  textClassName,
  loading,
  text,
  subText,
  subTextClassName,
  onSave,
}: SubtitleItemProps) {
  const inputRef = useRef<HTMLParagraphElement>(null);
  const prevTextRef = useRef(text);

  const onBlur = (ev: FocusEvent<HTMLDivElement>) => {
    const prevText = prevTextRef.current;
    const trimedText = ev.target.innerText.trim();

    if (prevText === trimedText) {
      console.log('无修改');

      if (prevText !== ev.target.innerText) {
        console.log('无修改但有空格');
        ev.target.innerText = prevText;
      }

      return;
    }

    if (ev.target.innerText === '') {
      console.log('不允许为空');
      ev.target.innerText = prevText;
      return;
    }

    prevTextRef.current = trimedText;
    ev.target.innerText = trimedText;

    console.log('修改保存', trimedText);

    const promiseLike = onSave?.(trimedText);

    if (promiseLike) {
      // 修改失败，改回去
      promiseLike.catch(() => {
        prevTextRef.current = prevText;
        ev.target.innerText = prevText;
      });
    }
  };

  const onKeyDown = (ev: KeyboardEvent<HTMLDivElement>) => {
    const el = ev.target as HTMLDivElement;

    // 看下是否保存
    if (ev.code === 'Enter') {
      ev.preventDefault();
      el.blur();
    }
    // 取消修改
    if (ev.code === 'Escape') {
      el.innerText = prevTextRef.current;
      el.blur();
    }
  };

  const onClickRoot = (ev: MouseEvent<HTMLDivElement>) => {
    if ((ev.target as HTMLElement).isContentEditable) {
      return;
    }

    if (!inputRef.current) {
      return;
    }

    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(inputRef.current);
    range.collapse(false);

    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  return (
    <div className={className} onClick={onClickRoot}>
      <p
        ref={inputRef}
        className={textClassName}
        contentEditable={!loading}
        spellCheck={false}
        suppressContentEditableWarning
        onBlur={onBlur}
        onKeyDown={onKeyDown}
      >
        {text}
      </p>
      {subText && <p className={subTextClassName}>{subText}</p>}
    </div>
  );
}

export default SubtitleItem;
