import mqtt from 'mqtt';
import type { IClientOptions, MqttClient } from 'mqtt';
import dayjs from 'dayjs';

const { connect } = mqtt;

const Log = console.log;

// 连接选项
const makeOptions: (res: API.WebsiteMqttInfoResponse) => IClientOptions = (res) => {
  const { client_id, username, password } = res;

  return {
    // 认证信息
    clientId: client_id,
    username,
    password,

    clean: true,
    reconnectPeriod: 3000, // 应该是重连一次隔多少秒
    connectTimeout: 30 * 1000, // 超时时间
    keepalive: 10, // 存活时间（超过了才会转 reconnect 状态

    // GitHub 有 Content Security Policy 限制，不能使用外部的 Worker，导致 MQTT 无法连接
    // 看项目首页说明，改成 Native 就不会让 MQTT 注册 Worker，这个看上去是个计时器的东西，也不清楚干什么用的
    // PC 版不改这个也没影响
    timerVariant: 'native',
  };
};

interface IOpt<R> {
  roomId: string;
  info: API.WebsiteMqttInfoResponse;
  topics: string[];
  getToken: () => Promise<API.WebsiteMqttInfoResponse>;
  onLoaded: () => void;
  onMessage?: (msg: R) => void;
  /**
   * 可用状态回调，只有开和关两个
   * @param {boolean} status
   * @returns void
   */
  onStatusChange?: (status: boolean) => void;
}

class SubtitleMQTTClient<R> {
  // @ts-ignore
  client: MqttClient;

  protected params: IOpt<R>;

  protected options: IClientOptions;

  private tokenExpireTime: number;

  private status = false;

  constructor(options: IOpt<R>) {
    this.params = options;
    this.options = makeOptions(options.info);
    this.tokenExpireTime = dayjs().add(1, 'hour').unix();

    this.initAsync().then(() => {
      this.params.onLoaded();
    });
  }

  /**
   * 检查 Token 是否有效
   * @description 超过 15 分钟则重新获取以保证可用性
   * @returns {Promise}
   */
  checkToken = async () => {
    if (dayjs().unix() > this.tokenExpireTime) {
      const info = await this.params.getToken();
      this.options = makeOptions(info);
      this.tokenExpireTime = dayjs().add(1, 'hour').unix();
    }

    return this.options;
  };

  /**
   * 初始化（同步）
   * @description 构造函数里面用吧
   * @returns
   */
  initAsync = () => new Promise(this.init);

  /**
   * 初始化
   */
  init = async (resolve?: any) => {
    this.options = await this.checkToken();

    const client = connect(this.params.info.ws_url, this.options);

    client.on('error', (err) => {
      Log('Subtitle MQTT error', err.message);

      // Token 失效，强制重新获取，忽略有效期设置
      if (err.message.includes('Bad username or password')) {
        this.tokenExpireTime = 0;
        this.destroyAndInit();
        return;
      }

      this.onStatusChange(false);

      throw new Error(`SubtitleModule: Mqtt ${err.message}`);
    });

    client.on('close', () => {
      this.onStatusChange(false);

      Log('Subtitle MQTT close');
    });

    client.on('reconnect', () => {
      this.onStatusChange(false);

      Log('Subtitle MQTT reconnect');
    });

    client.on('offline', () => {
      this.onStatusChange(false);

      Log('Subtitle MQTT offline');
    });

    client.on('connect', () => {
      this.onStatusChange(true);

      Log('Subtitle MQTT connect');

      resolve?.();
    });

    client.on('message', (_, msg) => {
      this.params.onMessage?.(JSON.parse(msg.toString()));
    });

    this.client = client;
    this.subscribe(this.params.topics);
  };

  private destroyAndInit() {
    Log('Subtitle MQTT Force Reconnect');

    this.destroy();

    this.init();
  }

  public sendMessage(topic: string, msg: string, qos: 0 | 1 | 2 = 0) {
    return this.client.publishAsync(topic, msg, {
      qos,
    });
  }

  /**
   * 连接状态变化了
   * @param {boolean} newStatus
   * @returns void
   */
  public onStatusChange(newStatus: boolean) {
    if (newStatus === this.status) {
      return;
    }

    this.status = newStatus;

    this.params.onStatusChange?.(this.status);
  }

  public subscribe(topic: string | string[]) {
    Log('Subtitle MQTT Subscribe', topic, this.client);

    return new Promise((resolve, reject) => {
      if (!this.client) {
        reject(new Error('Subscribe no client'));
        return;
      }

      this.client.subscribe(topic, { qos: 1 }, (err) => {
        if (err) {
          reject(err);
        } else {
          Log({
            type: 'EVENT',
            msg: { action: 'subscribe topic', topic, status: 'succ' },
            name: 'SUB-MQTT',
          });

          resolve(0);
        }
      });
    });
  }

  public unsubscribe() {
    if (!this.client) {
      return;
    }

    Log('Subtitle MQTT Unsubscribe', this.params.topics);
    this.client.unsubscribe(this.params.topics);
  }

  /**
   * 销毁
   */
  public destroy() {
    this.onStatusChange(false);

    this.client.end(true);
  }
}

export default SubtitleMQTTClient;
