import _ from 'lodash';
export const getLocaleTo = (path: string, prefix = '') => prefix + path;

// 转换语言字段
const localesMap = {
  ja: 'ja-JP',
  en: 'en-US',
  'zh-Hant': 'zh-TW',
  zh_CN: 'zh-CN',
  zh: 'zh-TW',
} as const;

const localesMapValues = Object.values(localesMap);

export const getLocale = (str: string): 'zh-CN' | 'zh-HK' | 'zh-TW' | 'en-US' | 'ja-JP' | undefined => {
  if (localesMapValues.includes(str as any)) {
    return str as any;
  }

  return localesMap[str] || str;
};

// 秒转分+秒
export const parseTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return [minutes, remainingSeconds];
};

// 下载文件
export const downloadFile = (url: string, name = '') => {
  fetch(url)
    .then((res) => res.blob())
    .then((res) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(res);
      link.download = name; // 可选，设置下载文件的名称
      link.click();
    });
};
// 根据json字符串下载文件
export const downloadFileByJson = (url: string, name = '') => {
  fetch(url)
    .then((res) => res.blob())
    .then((res) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(res);
      link.download = name; // 可选，设置下载文件的名称
      link.click();
    });
};
function downloadTxt(filename: string, text: string) {
  const link = document.createElement('a');
  const blob = new Blob([text], { type: 'text/plain' });

  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}
export const formateTextAndDownLoad = (msgs: API.TranslatorLogItem[], title: string) => {
  const printTxt: string[] = [];
  _.each(msgs, ({ text, translatedText }) => {
    if (!_.isEmpty(text)) {
      printTxt.push(`${text}`);
    }
    if (!_.isEmpty(translatedText)) {
      printTxt.push(`${translatedText}`);
    }
  });
  return downloadTxt(title, printTxt.join('\n'));
};
