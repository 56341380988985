import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Usage from './usage';
import BuyHistory from './buyHistory';
import './index.less';
import { getCurrentPlan } from '@/api/subtitles';

function Bill() {
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [item, setItem] = useState(0);
  const selectItem = (index: number) => {
    setItem(index);
  };
  const getPlanBalanceData = async () => {
    const res = await getCurrentPlan();
    if(res.data.data){
      setTotal(Math.floor(res.data.data.balance / 60));
    }

  }
  useEffect(() => {
    getPlanBalanceData();
  }, []);
  const openpage=(url:string)=>{
    window.open(import.meta.env.VITE_FELO_SUBTITLES_URL+url)
  }
  return (
    <div className="bill-content">
      <div className='bill-title'>{t('accountManage')}</div>
      <div className="bill-header">
        <div className="header-left-top-text">
          <div className="header-text">{t('totalTimeRemaining')}：</div>
          <div className="header-text-time">{t('minutes', { time: total })}</div>
        </div>
        <div className="buy-text" onClick={()=>openpage("/pricing")} >{t('topUp')}</div>
      </div>
      <div className='bill-content-container'>
        <div className='bill-menu-contnet'>
          <div className="bill-menus">
            <div className={clsx('bill-menu-item', item === 0 && 'activity')} onClick={() => selectItem(0)}>
              {t('buyData')}
            </div>
            <div className={clsx('bill-menu-item', item === 1 && 'activity')} onClick={() => selectItem(1)}>
              {t('usage')}
            </div>
          </div>
        </div>

        <div className="table-content">{item === 0 ? <BuyHistory /> : <Usage />}</div>
      </div>

    </div>
  );
}

export default Bill;
