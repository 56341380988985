import { Upload,message,Image } from 'antd';
import { useTranslation } from 'react-i18next';
import type { RcFile, UploadProps, } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import uploadIcon from "@/assets/icons/upload.svg";
import { useState,useRef } from 'react';
import styles from "./index.module.less";
import { uploadImageSign } from '@/api/subtitles';

const uploadFileToS3 = async (file: RcFile, actionUrl:string) => {
  try {
    // 使用 fetch API 发送 PUT 请求到 S3
    const response = await fetch(actionUrl, {
      method: 'PUT', // 注意使用 PUT 方法上传
      headers: {
        // 设置 Content-Type。保证这个值与你的文件类型匹配
        'Content-Type': file.type,
      },
      body: file, // 直接使用文件作为请求体
    });

    if (response.ok) { // HTTP 状态码 200-299 表示成功
      return { success: true };
    } else {
      // 处理错误情况
      console.error('error', response.statusText);
      return { success: false, message: response.statusText };
    }
  } catch (error) {
    // 异常处理
    console.error('error:', error);
    return { success: false, message: 'error'};
  }
};
const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
  interface UploadImageProps {
    setImagesList: (list: string[]) => void;
  }
const UploadImage= (props:UploadImageProps) => {
  const { t } = useTranslation();
  const {setImagesList}=props;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const listRef = useRef<UploadFile[]>([]);

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.thumbUrl);
    setPreviewOpen(true);
  };
  const getImageList = (list:UploadFile[])=>{
    const imageList: string[] =[];
    for(var i=0;i<list.length;i++){
      if(list[i].url){
        imageList.push(list[i].url as string);
      }
    }
    setImagesList(imageList)
  }
  const upload =async (file: RcFile, actionUrl:string)=>{
    try{
      const response = await uploadFileToS3(file, actionUrl);
      // 处理上传响应，例如设置上传后的图片 URL
      if (response.success) {
        var info = JSON.parse(JSON.stringify(listRef.current));
        for(var i=0;i<info.length;i++){
          if(info[i].lastModified ===file.lastModified){
            info[i].status ='done';
            info[i].url =actionUrl;
            info[i].thumbUrl = await getBase64(file);
          }
        }
        listRef.current = info;
        getImageList(info)
        setFileList(info);
        return true;
        // 处理成功的响应
      } else {
        var info = JSON.parse(JSON.stringify(listRef.current));
        for(var i=0;i<info.length;i++){
          if(info[i].lastModified ===file.lastModified){
            info[i].status ='error';
            info[i].url =actionUrl;
            info[i].thumbUrl = await getBase64(file);
          }
        }
        listRef.current = info;
        setFileList(info);
        // 上传失败
        message.error(t('failUpload'));
      }
    }catch(error){
      console.log('error',error);
    }

  }
  const beforeUpload = async (file: RcFile) => {
    try{
      if(file.name){
        const type = file.name.split('.').pop();
        if(type){
          const info ={suffix:type};
          const data = await uploadImageSign(info)
          // 你可以在这里通过一个自定义方法上传文件
          upload(file, data.data.data.url);
          return true;
        }
        return false;
      }
      return false;
    }catch(error){
      console.log(error);
      message.error(t('failUpload'));
    }

  };
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>{
    listRef.current = newFileList;
    getImageList(newFileList)
    setFileList(newFileList);
  }


  const uploadButton = (
    <div className={styles.uploadButton}>
      <img src={uploadIcon} className={styles.uploadIcon}/>
      <div className={styles.uploadText}>({fileList.length}/6)</div>
    </div>
  );
  const customRequest=(file: any)=>{
    return true;
  }
  const onVisibleChange=(previewOpen: boolean)=>{
    console.log(previewOpen)
    setPreviewOpen(previewOpen)
  }
  return (
    <>
      <Upload
        accept=".png,.jpeg,.jpg,.bmp,.gif,"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        className={styles.uploadCustom}
        maxCount={6}
      >
        {fileList.length >= 6 ? null : uploadButton}
      </Upload>
      <Image
        width={200}
        preview={{visible:previewOpen,onVisibleChange:onVisibleChange}}
        src={previewImage}
        style={{display:'none'}}
      />
    </>
  );
};

export default UploadImage;
