import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
const instance = axios.create({
  baseURL: import.meta.env.VITE_FELO_API_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config: any) => {
    // 处理重复请求
    // const token = window.localStorage.getItem('subtitlestoken');
    // if (token) {
    //   config.headers.Authorization = token;
    // }
    config.headers.Authorization = Cookies.get(import.meta.env.VITE_COOKIE_SUFFIX as string);
    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response) => {
    // 移除重复请求
    return response;
  },
  (error: AxiosError) => {
    // 移除重复请求
    return Promise.reject(error);
  },
);

export default instance;
