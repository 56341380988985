import { ConfigProvider } from 'antd';
import jaJP from 'antd/lib/locale/ja_JP';
import enUS from 'antd/lib/locale/en_US';
import zhTW from 'antd/lib/locale/zh_TW';
import zhCN from 'antd/lib/locale/zh_CN';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import i18n from '@/utils/i18n';
import 'dayjs/locale/ja';
import 'dayjs/locale/zh-cn';
const getAntdLocale = (lang: string) => {
  const langCode = lang.toLowerCase();

  if (langCode.startsWith('ja')) {
    return jaJP;
  }
  if (langCode.indexOf('cn') >= 0) {
    return zhCN;
  }
  if (langCode.indexOf('tw') >= 0) {
    return zhTW;
  }
  if (langCode.indexOf('zh') >= 0) {
    return zhCN;
  }
  return enUS;
};
export function AntdProvider({ children }: PropsWithChildren<unknown>) {
  const [antdLocale, setAntdLocale] = useState(() => getAntdLocale(i18n.language));
  const handleLanguageChange = useCallback((lang: string) => {
    setAntdLocale(getAntdLocale(lang));
  }, []);
  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChange);
    return () => i18n.off('languageChanged', handleLanguageChange);
  }, [handleLanguageChange]);

  return <ConfigProvider locale={antdLocale}>{children}</ConfigProvider>;
}
export default AntdProvider;
